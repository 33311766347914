/* Bio text responsive behavior */
.bioText {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  max-width: 40vw;
  min-width: 250px;
  padding: 1rem;
  color: white;
  font-family: 'Silkscreen', cursive;
  font-size: clamp(0.7rem, 1.2vw, 1rem);
  text-align: left;
  transition: all 0.3s ease;
}

@media (max-width: 1024px) {
  .bioText {
    max-width: 60vw;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  }
}

@media (max-width: 768px) {
  .bioText {
    max-width: 80vw;
    right: auto;
    left: 0rem;
    font-size: clamp(0.6rem, 2vw, 0.8rem);
    text-align: left;
    padding: 0.5rem;
  }
  .navLinks{
    left: 0rem;
  }
}

@media (max-width: 480px) {
  .bioText {
    max-width: 90vw;
    font-size: 0.7rem;
    min-width: unset;
    width: calc(100% - 2rem);
  }
}